<template>
  <div class="container" >
    <h3 class="text-center text-danger" v-if="loading">
       <b> <i> <u>{{ $route.params.slug }}</u> </i> </b> Products loading...
    </h3>

    <h3 class="text-center text-danger" v-if="showErrorText">
      No Product found for <b> <i> <u>{{ $route.params.slug }}</u> </i> </b>
    </h3>

    <Skeleton v-else height="400px"/>
  </div>
</template>

<script>
export default {
  name: "NoProductFound",
  data(){
    return{
      showErrorText : false,
      loading:true
    }
  },
  methods:{
    processErrorText() {
      setTimeout(function () {
          this.showErrorText=true
          this.loading = false
        }.bind(this), 5000)
    }
  },
  mounted() {
    this.processErrorText()
  }
}
</script>

<style scoped>

</style>